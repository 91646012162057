<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="学生姓名">
              <a-input allow-clear v-model="form.name" placeholder="请输入姓名或学号" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="学员科目">
              <a-select allow-clear v-model="form.subject" placeholder="请选择">
                <a-select-option v-for="item in subjectArr" :key="item.value" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <!-- <a-col :span="6" >
              <a-form-item label="组织(部门)">
                <a-tree-select
                  allow-clear
                  v-model="form.departmentId"
                  style="width: 100%"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  :tree-data="treeData"
                  placeholder="请选择"
                  tree-default-expand-all
                >
                </a-tree-select>
              </a-form-item>
            </a-col>
            <a-col :span="6">
              <a-form-item label="流失前班主任">
                <a-select
                  show-search
                  @search="searchClassAdmin"
                  v-model="form.classAdmin"
                  :filter-option="filterOption"
                  option-filter-prop="children"
                  placeholder="请选择"
                  allow-clear
                >
                  <a-select-option :value="i.uuid" v-for="i of classAdminList" :key="i.uuid">
                    {{ i.fullName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col> -->
          <a-col :span="7">
            <a-form-item label="学习规划师交接时间">
              <a-range-picker
                @change="onChange"
                v-model="form.dateRange"
                style="width: 100%"
                :show-time="{
                  format: 'HH:mm',
                  defaultValue: [$moment('00:00:00', 'HH:mm:ss'), $moment('23:59:59', 'HH:mm:ss')],
                }"
                format="YYYY-MM-DD HH:mm"
              />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24" style="float: right; text-align: right">
            <span class="table-page-search-submitButtons" :style="{ float: 'right', overflow: 'hidden' } || {}">
              <a-button style="margin-right: 10px" type="primary" @click="queryAssignStudents('search')">
                查询
              </a-button>
              <a-button style="margin-right: 10px" @click="resetForm"> 重置 </a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-wrap">
      <div style="margin-bottom: 12px; font-size: 14px; font-weight: bold">
        待分配学员：{{ this.initPagination.total }} 名
      </div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="initPagination"
        :loading="loading"
        @change="handleTableChange"
      >
        <span slot="residentCountry" slot-scope="residentCountry">{{
          initOptionsType(residentCountry, countries)
        }}</span>
        <span slot="subject" slot-scope="text">{{ getDdlLabel(subjectArr, text) }}</span>
        <span slot="completionStatus" slot-scope="text">{{ getDdlLabel(completionStatusArr, text) }} </span>
        <span slot="studentName" slot-scope="studentName, record">{{
          `${record.data.studentName}(${record.data.studentCode})`
        }}</span>

        <span slot="action" slot-scope="text, record" style="word-break: keep-all">
          <a @click="lookAssignFun(record)"> 指定分配 </a>
        </span>
      </a-table>
    </div>

    <a-modal title="分配班主任" width="900px" :visible="visibleResult" :footer="null" @cancel="cancelAssign">
      <div class="red-title">未分配原因：{{ tableListItem.data.reasonMsg }}</div>
      <div>
        为{{ getDdlLabel(subjectArr, tableListItem.subject) }}学员<span style="color: #04cb94">{{
          `${tableListItem.data.studentName}(${tableListItem.data.studentCode})`
        }}</span
        >分配班主任：
      </div>
      <div class="search-wrap">
        <div class="search-item">
          <div class="search-label" style="width: 59px">组织：</div>
          <a-tree-select
            allow-clear
            v-model="departmentId"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="treeData"
            placeholder="请选择"
            tree-default-expand-all
          >
          </a-tree-select>
        </div>
        <div class="search-item">
          <div class="search-label" style="width: 59px">科目：</div>
          <a-select allowClear style="width: 100%" placeholder="请选择" v-model="stuSubject">
            <a-select-option :value="item.value" v-for="(item, index) in subjectArr" :key="index">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="search-item">
          <div class="search-label" style="width: 80px">班主任：</div>
          <a-input
            allow-clear
            v-model="assignName"
            placeholder="请输入姓名"
            @pressEnter="queryAssignClassAdmin('search')"
          />
        </div>
        <a-button type="primary" @click="queryAssignClassAdmin('search')"> 查询 </a-button>
      </div>
      <div style="height: 550px">
        <a-table
          size="small"
          :scroll="{ y: 450 }"
          :columns="subColumns"
          :data-source="subTableData"
          :pagination="subPagination"
          :loading="subLoading"
          @change="handleSubChange"
        >
          <span slot="fullName" slot-scope="fullName, record">{{ `${fullName}(${record.email || ''})` }}</span>
          <template slot="shiftTime" slot-scope="shiftTime, record">
            <span v-if="record.accountData && record.accountData.isLeave">休息</span>
            <span v-if="!(record.accountData && record.accountData.isLeave) && shiftTime && shiftTime.length > 0">
              {{
                $moment(shiftTime[0].offTime, 'HH:mm').diff($moment(shiftTime[0].onTime, 'HH:mm'), 'h') <= 20
                  ? `${shiftTime[0].onTime} - ${shiftTime[0].offTime}`
                  : '休息'
              }}
            </span>
          </template>
          <span slot="monthUsed" slot-scope="monthUsed, record"
            >{{ monthUsed }}/<span style="color: #04cb94">{{ record.monthLimit }}</span></span
          >
          <span slot="action" slot-scope="text, record" style="word-break: keep-all">
            <a @click="atOnceAssignFun(record)"> 立即分配 </a>
          </span>
        </a-table>
      </div>
    </a-modal>
    <a-modal
      title="分配确认"
      :okButtonProps="{
        props: {
          loading: iconLoading,
        },
      }"
      :visible="visibleAssign"
      @ok="confirmAssign"
      @cancel="cancelSubAssign"
    >
      <div>
        确定要将{{ getDdlLabel(subjectArr, tableListItem.subject) }}学员<span style="color: #04cb94">{{
          `${tableListItem.data.studentName}(${tableListItem.data.studentCode})`
        }}</span
        >分配给<span style="color: #04cb94">{{ subTableItem.fullName }}</span
        >班主任吗？
      </div>
    </a-modal>
  </div>
</template>
<script>
import { getDdlLabel } from '@/utils/util';
import { getTreeList, selClassAdmin, countries } from '@/api/headTeacher';
import { queryAssignStudents, queryAssignClassAdmin, confirmAssign } from '@/api/set';

export default {
  data() {
    return {
      subjectArr: [
        { label: '中文', value: 'CHINESE' },
        { label: '数学', value: 'MATH' },
        { label: '英文', value: 'ENGLISH' },
      ],
      stuSubject: undefined,
      countries: null,
      iconLoading: false,
      tableListItem: {
        data: {},
      },
      subTableItem: {},
      visibleAssign: false,
      subPageNum: 1,
      subPageSize: 10,
      subCurrent: 0,
      departmentId: undefined,
      assignName: undefined,
      subLoading: false,
      subTableData: [],
      tableData: [],
      classAdminList: [],
      treeData: [],

      courseData: {},
      recordObj: {},
      completionStatusArr: [
        { label: '未处理', value: 'NORMAL' },
        { label: '已完成', value: 'COMPLETED' },
      ],
      visibleResult: false,
      getDdlLabel,
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      form: {
        name: undefined,
        startDateTime: null,
        endDateTime: null,
        dateRange: [],
        subject: undefined,
      },
      subPagination: {
        total: 0,
        current: 1,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      },
      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      },
      pageNum: 1,
      pageSize: 10,
      current: 0,
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '学生',
          dataIndex: 'studentName',
          key: 'studentName',
          scopedSlots: { customRender: 'studentName' },
          customCell: (record) => {
            return {
              style: {
                // 行背景色
                // color: '#43D186',
                cursor: 'pointer',
              },
              on: {
                // 鼠标单击行
                click: () => {
                  const routeUrl = this.$router.resolve({
                    path: '/studentDetail',
                    query: {
                      uuid: record.studentId,
                    },
                  });
                  window.open(routeUrl.href, '_blank');
                },
              },
            };
          },
        },
        {
          title: '科 目',
          dataIndex: 'subject',
          key: 'subject',
          scopedSlots: { customRender: 'subject' },
        },
        {
          title: '家长邮箱',
          key: 'email',
          dataIndex: 'data.email',
        },
        {
          title: '常驻国家/地区',
          dataIndex: 'data.residentCountry',
          key: 'residentCountry',
          scopedSlots: { customRender: 'residentCountry' },
        },
        {
          title: '级别',
          dataIndex: 'data.level',
          key: 'level',
        },
        {
          title: '沟通语言',
          dataIndex: 'data.communicationLanguage',
          key: 'communicationLanguage',
        },
        {
          title: '学习规划师',
          dataIndex: 'data.ccName',
          key: 'ccName',
        },
        {
          title: '学习规划师交接时间',
          key: 'whenCreated',
          dataIndex: 'whenCreated',
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      subColumns: [
        {
          title: '序号',
          key: 'index',
          width: 60,

          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.subCurrent - 1) * this.subPageSize + (index + 1)}`,
        },
        {
          title: '组织',
          dataIndex: 'departmentString',
          key: 'departmentString',
        },
        {
          title: '班主任姓名',
          dataIndex: 'fullName',
          key: 'fullName',
          scopedSlots: { customRender: 'fullName' },
        },
        {
          title: '今日在班时间',
          dataIndex: 'accountData.shiftTime',
          key: 'shiftTime',
          scopedSlots: { customRender: 'shiftTime' },
          width: 130,
        },
        {
          title: '本月分配名额',
          dataIndex: 'monthUsed',
          key: 'monthUsed',
          scopedSlots: { customRender: 'monthUsed' },
          width: 130,
        },
        {
          title: '今日可分配名额',
          key: 'dayRemain',
          dataIndex: 'dayRemain',
          width: 130,
        },
        {
          title: '操作',
          key: 'action',
          width: 80,
          scopedSlots: { customRender: 'action' },
        },
      ],
    };
  },
  created() {
    countries().then((i) => {
      this.countries = i.data.content;
    });
    this.getTreeList();
    this.queryAssignStudents();
  },
  methods: {
    initOptionsType(type, typeArr) {
      if (!typeArr || typeArr.length < 1) {
        return;
      }
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.name === type) {
          name = item.label;
        }
      });
      return name;
    },
    cancelSubAssign() {
      this.visibleAssign = false;
    },
    confirmAssign() {
      this.iconLoading = true;
      const params = {
        classAdminId: this.subTableItem.uuid,
        allocateAssignId: this.tableListItem.uuid,
      };
      confirmAssign(params)
        .then((res) => {
          this.$message.success('分配成功');
          this.visibleAssign = false;
          this.visibleResult = false;
          this.queryAssignStudents('search');
        })
        .finally(() => {
          this.iconLoading = false;
        });
    },
    atOnceAssignFun(record) {
      this.subTableItem = record;
      this.visibleAssign = true;
    },
    handleTableChange(data, filters, sorter) {
      this.pageNum = data.current;
      this.initPagination.current = data.current;
      this.queryAssignStudents();
    },
    queryAssignClassAdmin(act) {
      this.subLoading = true;
      if (act === 'search') {
        this.subPageNum = 1;
      }
      const params = {
        departmentId: this.departmentId,
        name: this.assignName || undefined,
        pageSize: this.subPageSize,
        accountData: {
          subject: this.stuSubject,
        },
      };
      if (this.departmentId) {
        params.departmentFuzzy = true;
      }
      queryAssignClassAdmin(params, this.subPageNum).then((res) => {
        this.subLoading = false;
        this.subTableData = res.data.content;
        this.subCurrent = res.data.currentPage;
        this.subPageSize = res.data.pageSize;
        this.subPagination.total = res.data.totalCount;
        this.subPagination.current = res.data.currentPage;
      });
    },
    handleSubChange(data) {
      this.subPageSize = data.pageSize;
      this.subPageNum = data.current;
      this.subPagination.current = data.current;
      this.subPagination.pageSize = data.pageSize;
      this.queryAssignClassAdmin();
    },
    cancelAssign() {
      this.visibleResult = false;
    },
    lookAssignFun(record) {
      if (!record.data.isSubscribe) {
        this.stuSubject = record.subject;
      }

      this.subTableData = [];
      this.tableListItem = record;
      this.visibleResult = true;
      this.departmentId = undefined;
      this.assignName = undefined;
      this.queryAssignClassAdmin();
    },
    handleTableChange(data, filters, sorter) {
      this.pageSize = data.pageSize;
      this.pageNum = data.current;
      this.initPagination.current = data.current;
      this.initPagination.pageSize = data.pageSize;
      this.queryAssignStudents();
    },
    queryAssignStudents(act) {
      this.loading = true;
      if (act === 'search') {
        this.pageNum = 1;
      }
      const params = {
        status: ['FAILED', 'SUBSCRIBE'], // 固定值
        name: this.form.name, // 学生姓名
        subject: this.form.subject, // 科目
        startDateTime: this.form.startDateTime, // 顾问开始交接时间
        endDateTime: this.form.endDateTime, // 顾问交接结束时间
        pageSize: this.pageSize,
      };
      queryAssignStudents(params, this.pageNum).then((res) => {
        this.loading = false;
        this.tableData = res.data.content;
        this.current = res.data.currentPage;
        this.pageSize = res.data.pageSize;
        this.initPagination.total = res.data.totalCount;
        this.initPagination.current = res.data.currentPage;
      });
    },
    searchClassAdmin(name) {
      const params = {
        name,
      };
      selClassAdmin(params).then((res) => {
        this.classAdminList = res.data.content;
      });
    },
    getTreeList() {
      getTreeList().then((res) => {
        this.treeData = res.data;
      });
    },

    cancelResult() {
      this.visibleResult = false;
    },
    onChange(date) {
      this.form.startDateTime = date[0].format('YYYY-MM-DD HH:mm:ss');
      this.form.endDateTime = date[1].format('YYYY-MM-DD HH:mm:ss');
    },
    resetForm(e) {
      e.preventDefault();
      this.traverseReset(this.form);
      this.queryAssignStudents();
    },
    traverseReset(form) {
      for (const i in form) {
        if (!!form[i] && Object.prototype.toString.call(form[i]) === '[object Object]') {
          this.traverseReset(form[i]);
        } else {
          form[i] = undefined;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.search-label {
  text-align: right;
}
.ant-form-item {
  margin-bottom: 0px;
}
.trans-wrap {
  background: rgba(0, 0, 0, 0.02);

  padding: 10px 20px;
}
.trans-itemWrap {
  display: flex;
  flex-wrap: wrap;
}
.trans-item {
  width: 50%;
  margin-bottom: 10px;
}
.trans-title {
  font-size: 14px;
  margin-bottom: 20px;
}
.warn-txt {
  color: red;
  margin-top: 20px;
}
/deep/ .table-page-search-wrapper .ant-form-inline .ant-form-item > .ant-form-item-label {
  width: 105px;
  text-align: right;
}
.table-page-search-wrapper {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 20px 0;
}
.ant-advanced-search-form {
  /deep/ .ant-col-16 {
    width: 70%;
  }
  /deep/ .ant-form-item-label {
    width: 68px;
  }
}
.table-wrap {
  padding: 20px;
  background: #fff;
}
.red-title {
  color: red;
  margin-bottom: 12px;
}
.search-wrap {
  display: flex;
  margin: 20px 0 15px;
}
.search-item {
  width: 250px;
  display: flex;
  align-items: center;
  margin-right: 20px;
}
</style>
